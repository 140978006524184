import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import img1 from "../../images/icons/bx-check-double.svg"

const Wrapper = styled.div`
  border: 1px solid #e1e2e3;
  padding: 16px;
  border-radius: 6px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

const EH3 = styled.h3`
  font-size: 22px;
  font-weight: 600;
  color: #111;
  margin: 0;
  margin-bottom: 0.4em;
`

const IconsColumns = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 16px;
  margin-top: 2em;
  align-items: center;

  p {
    font-size: 1.2em;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    color: #111;
    margin: 0;
    line-height: 1.8;
  }
`

export const Requirements = ({ lectionsCount, practicalCount, weeks }) => {
  return (
    <>
      <Wrapper>
        <EH3>Потрібні знання</EH3>

        <IconsColumns>
          <>
            <div>
              <img src={img1} />
            </div>
            <p>{weeks} Бажання вчитись</p>
          </>

          <div>
            <img src={img1} />
          </div>
          <p>{lectionsCount} Базові знання HTML, CSS</p>

          <>
            <div>
              <img src={img1} />
            </div>
            <p>{practicalCount} Розуміння алгоритмів</p>
          </>

          <>
            <div>
              <img src={img1} />
            </div>
            <p>
              Англійська В1 (рекомендаційні матеріали будуть часто англійською)
            </p>
          </>

          <>
            <div>
              <img src={img1} />
            </div>
            <p>
              Успішне виконання тестового завдання для проходження у навчальну
              групу
            </p>
          </>
        </IconsColumns>
      </Wrapper>
    </>
  )
}
