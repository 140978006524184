import React from "react"
import styled from "@emotion/styled"

export const Ewrapper = styled.div`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  position: relative;
  padding: 0 2em;
  transition: all 0;

  @media (max-width: 640px) {
    padding: 0 0.18em;
  }
`

export const Efigure = styled.figure`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 16px;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  margin: 0;
  width: 100%;
`

const EFigcaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const EName = styled.p`
  color: #333;
  margin: 0;
  font-size: 1.4em;
  text-align: center;
  font-weight: 600;

  img {
    max-height: 1em !important;
  }
`

const EPosition = styled.div`
  display: flex;
  font-size: 1.3em;
  color: #8f96a1;
  margin: 0;
  text-align: initial;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.01em;
  margin-top: 0.5em;
`

const EImg = styled.img`
  border-radius: 50%;
  width: 4.5em !important;
  height: 4.5em !important;
  object-fit: cover;
`

export const Emsg = styled.div`
  max-height: 11em;
  overflow: hidden;

  p {
    position: relative;
    margin: 0;
    font-size: 1.3em;
    color: #525151;
    @media (max-width: 900px) {
      line-height: 1.4;

      font-size: 14px;
    }
  }

  @media (max-width: 641px) {
    max-height: 11.8em;
  }

  &[data-expanded="true"] {
    max-height: unset;

    @media (max-width: 641px) {
      max-height: unset !important;
    }
  }
`

const EPersonImgWrapper = styled.div`
  position: relative;
`

const ELearnMore = styled.button`
  position: relative;
  color: #fd7114;
  font-weight: 400;
  background: none;
  border: 0;
  outline: none;
  font-size: 1.3em;
  margin: 0;
  padding: 0;
  margin-top: 0.2em;

  &[data-display="false"] {
    display: none !important;
  }
`

const Component = ({
  name,
  purpose,
  msg,
  photo,
  MessageFontSizeEms,
  noExtend,
  expanded,
  componentNextToName,
  toggleExpandedState,
  id,
}) => {
  return (
    <Ewrapper>
      <Efigure>
        <EPersonImgWrapper>
          <EImg src={photo} alt={name} loading="lazy" />
        </EPersonImgWrapper>

        <EFigcaption>
          <EName>
            {name}
            {!!componentNextToName && <>{componentNextToName}</>}
          </EName>
          <EPosition>{purpose}</EPosition>
        </EFigcaption>
      </Efigure>

      <Emsg fontSize={MessageFontSizeEms} data-expanded={expanded}>
        {msg()}
      </Emsg>

      <ELearnMore
        onClick={() => {
          toggleExpandedState(id)
        }}
        data-display={expanded}
      >
        Менше
      </ELearnMore>

      <ELearnMore
        onClick={() => {
          toggleExpandedState(id)
        }}
        data-display={!expanded}
      >
        Читати далі
      </ELearnMore>
    </Ewrapper>
  )
}

export const CarouselElementCourseStyle = React.memo(
  (props) => (
    <Component
      name={props.name}
      purpose={props.purpose}
      msg={props.msg}
      photo={props.photo}
      imgs={props.imgs}
      MessageFontSizeEms={props.MessageFontSizeEms}
      noExtend={props.noExtend}
      expanded={props.expanded}
      componentNextToName={props.componentNextToName}
      toggleExpandedState={props.toggleExpandedState}
      id={props.id}
    />
  ),

  (prev, next) =>
    prev.expanded === next.expanded && prev.noExtend === next.noExtend
)
