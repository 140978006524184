import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { WhatYoullGet } from "./WhatYoullGet"
import { Mentors } from "./Mentors"
import { AlmostRealJob } from "./AlmostRealJob"
import { ScheduleList } from "./ScheduleList"
import { Requirements } from "./Requirements"
import { SharedDivider } from "../shared"
import { FeedbackCarouselCourseStyle } from "../feedbackCarousel/FeedbackCarouselCourseStyle"
import { Packages } from "./Packages"
import { FaqCourse } from "./FaqCourse"
import { Wrapper, ContentWrapper } from "./CourseLectionsSwitcher"
import { tabs } from "../../templates/Course"
import { SharedH3 } from "../shared"

export const EH2 = styled.h2`
  padding: 0;
  margin: 0;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  white-space: nowrap;
  color: #000;

  margin: 2em 0 0.7em 0;

  @media (max-width: 640px) {
    text-transform: uppercase;
    text-align: center;
    margin: 3em 0 1.2em 0;
    color: #8f96a1;
  }

  &[data-indexfirst="true"] {
    margin-top: 1.2em;
  }
`

export const CourseLectionsSwitcherRelevant = React.memo(
  ({
    name,
    lections,
    relevant,
    team,
    schedule,
    pricePerCourse,
    pricePerMonth,
    discount,
    questions,
    registerLink,
    registrationClosed,
    videos,
    pracricalLessons,
    duration,
    freeAccessLink,
    learnOnYOurselfPrice,
    buyOnUdemyCaption,
    buyOnUdemyCaption2,
    priceString,
    activeTab,
    registerLinkText,
    goLearnYourselfLink,
  }) => {
    return (
      <>
        {activeTab === tabs[0] && (
          <Wrapper>
            <ContentWrapper>
              <WhatYoullGet
                lectionsCount={lections ? lections.length : 0}
                practicalCount={null}
                duration={duration}
                videos={videos}
                pracricalLessons={pracricalLessons}
              />

              <SharedDivider />

              <SharedH3
                css={css`
                  font-size: 2em;
                `}
              >
                Курс підготували
              </SharedH3>

              {team &&
                team.map((i, index) => (
                  <React.Fragment key={`mentor${index}${i.linkedin}`}>
                    {i.header && (
                      <EH2 data-indexfirst={index === 0}>{i.header}</EH2>
                    )}
                    <Mentors
                      name={i.name}
                      position={i.position}
                      description={i.description}
                      pic={i.pic}
                      linkedin={i.linkedin}
                      index={index}
                    />
                  </React.Fragment>
                ))}

              <SharedDivider />

              <AlmostRealJob />

              <SharedDivider data-count={3} />

              <FeedbackCarouselCourseStyle />
            </ContentWrapper>
          </Wrapper>
        )}

        {activeTab === tabs[1] && (
          <Wrapper>
            <ContentWrapper>
              <ScheduleList schedule={schedule} />
            </ContentWrapper>
          </Wrapper>
        )}

        {activeTab === tabs[2] && (
          <Wrapper>
            <ContentWrapper>
              <Requirements />
            </ContentWrapper>
          </Wrapper>
        )}

        {activeTab === tabs[3] && (
          <Wrapper>
            <ContentWrapper>
              <Packages
                pricePerCourse={pricePerCourse}
                pricePerMonth={pricePerMonth}
                discount={discount}
                registerLink={registerLink}
                freeAccessLink={freeAccessLink}
                learnOnYOurselfPrice={learnOnYOurselfPrice}
                buyOnUdemyCaption={buyOnUdemyCaption}
                buyOnUdemyCaption2={buyOnUdemyCaption2}
                priceString={priceString}
                registrationClosed={registrationClosed}
                registerLinkText={registerLinkText}
                goLearnYourselfLink={goLearnYourselfLink}
                name={name}
              />
            </ContentWrapper>
          </Wrapper>
        )}

        {activeTab === tabs[4] && (
          <Wrapper>
            <ContentWrapper
              css={css`
                > div > div {
                  padding: 0;
                }
              `}
            >
              <FaqCourse questions={questions} />
            </ContentWrapper>
          </Wrapper>
        )}

        <SharedDivider data-count={2} />
      </>
    )
  },
  (prev, next) => prev.activeTab === next.activeTab
)
