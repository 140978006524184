import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import {
  InfoCard,
  EInfoCardColumn,
  EPrice,
  InfoCardWrapper,
} from "./CourseHeader"
import { orangeButtonColors } from "../shared"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"


export const ESearch = styled.a`
  ${orangeButtonColors}

  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  min-height: 3.5em;
  p {
    font-size: 1.5em;
    margin: 0;
  }
  right: 0;
  text-decoration: none;
`

const handleUdemyClick = () => {
  trackCustomEvent({
    action: "frontendudemyclick",
    category: "myclick1",
  })
}

const EImg1 = styled(Img)`
  border-radius: 4px;
  img {
    object-fit: cover !important;
  }
`

const EP1 = styled.p`
  grid-column: 1 / -1;
  color: #000 !important;

  @media (min-width: 701px) {
    font-weight: 600 !important;
  }
`

export const FrontEnd2021InfoCard = ({
  pricePerCourse,
  secondInfoCardCourseImg,
  scrollPosition,
}) => {
  return (
    <InfoCardWrapper>
      <InfoCard>
        <EPrice>
          <p
            css={css`
              font-size: 1.2em;
              font-weight: 500;
              letter-spacing: -0.03em;
              margin: auto;
              padding-bottom: 1em;
              text-align: center;
              @media (max-width: 640px) {
                margin: auto !important;
              }

              @media (min-width: 1180px) {
                margin: 0 auto !important;
              }
            `}
          >
            Самостійне навчання
          </p>
        </EPrice>

        {secondInfoCardCourseImg && (
          <EImg1
            durationFadeIn={200}
            fluid={secondInfoCardCourseImg.node.childImageSharp.fluid}
            scrollPosition={scrollPosition}
          />
        )}

        <div
          css={css`
            display: grid;
            grid-column-gap: 0.6em;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1em;
            margin-top: 1.6em;
          `}
        >
          <EPrice>
            <p
              css={css`
                font-weight: 500;
              `}
            >
              $ 34,99
            </p>
            /курс
          </EPrice>
        </div>

        {pricePerCourse && (
          <p
            css={css`
              color: #8f96a2;
              font-size: 0.88em;

              @media (max-width: 640px) {
                font-size: 10px;
              }
            `}
          >
            *При оплаті курсу одним платежем
          </p>
        )}

        <EInfoCardColumn>
          <p>Проведення: </p>
          <p>Online</p>
          <p>Мова:</p>
          <p>Українська</p>
          <EP1 scrollPosition={scrollPosition}>Доступ до курсу назавжди</EP1>
        </EInfoCardColumn>

        <ESearch
          href={
            "https://www.udemy.com/course/aaspring21-frontend-course/?referralCode=EEE2642758D683E7550E&persist_locale=&locale=ru_RU"
          }
          target="_blank"
          rel="noreferrer"
          onClick={handleUdemyClick}
        >
          <p>Пройти самостійно</p>
        </ESearch>
      </InfoCard>
    </InfoCardWrapper>
  )
}
