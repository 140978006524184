import React, { useState } from "react"
import { css } from "@emotion/react"
import { CarouselElementCourseStyle } from "./CarouselElementCourseStyle"
import Slider from "react-slick"
import styled from "@emotion/styled"
import { feedbackList } from "./FeedbackCarousel"
import { SharedH3, SharedDivider } from "../shared"

const arrowStyle = css`
  outline: 0;
  z-index: 10;
  cursor: pointer;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: fit-content;
  margin: 10px;
  overflow: visible;
  width: 11px !important;
  height: 18px !important;
  top: 1.3em;

  @media (max-width: 900px) {
    width: 10px !important;
    width: 15px !important;
  }
`

const PrevArrow = styled.button`
  left: 0;
  background-color: transparent !important;
  ${arrowStyle}
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.12 11.41'%3E%3Ctitle%3EVector%3C/title%3E%3Cpath d='M6.29.29.59,6l5.7,5.71,1.42-1.42L3.41,6l4.3-4.29Z' transform='translate(-0.59 -0.29)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  margin: auto auto auto -2em;
  z-index: 23;
`

const NextArrow = styled.button`
  right: 0;
  margin-left: -2.25em;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent !important;
  ${arrowStyle}
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.19 11.42'%3E%3Ctitle%3E43141%3C/title%3E%3Cpath d='M1.33,11.39,7.12,5.77,1.51,0,.07,1.36,4.3,5.72-.07,9.94Z' transform='translate(0.07 0.03)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 23;
  margin: auto -2em auto auto;
`

const ESection = styled.section`
  .slick-list {
    transition: all 0;
  }

  position: relative;
  max-width: 103em;
  margin: 0 auto;
  padding: 0 2.5em;

  .slick-dots {
    width: 100%;
    margin-top: 14px;
    padding: 0;
    margin: 2.5em 0 0 0;
    display: flex !important;
    justify-content: center;
    align-items: center;

    button {
      background-color: transparent;
      border: 1px solid #fd7114;
      cursor: pointer;
      content: "";
      width: 7px;
      height: 7px;
      padding: 0;
      border-radius: 100%;
      font-size: 0.001px;
      background-color: transparent;
      transition: all 0;
      :hover {
        background-color: #fff7ee;
        transform: scale(1.4);
      }
    }

    .slick-arrow {
      height: fit-content;
      width: fit-content;
    }

    li {
      transition: all 0;
      list-style-type: none;
      border-radius: 100%;

      margin: 7px;
      display: block;

      button {
        display: block;
        width: 8x;
        height: 8x;
      }

      @media (max-width: 900px) {
        margin: 4px;
        width: 5px;
        height: 5px;
      }
    }

    .slick-active::marker {
      button {
        background-color: #fd7114 !important;
      }
    }

    .slick-active {
      visibility: visible !important;

      button {
        background-color: #fd7114 !important;
      }
    }
  }
`

const Component = React.memo(
  () => {
    const [feedbacks, setFeedbacks] = useState(
      feedbackList.map((i) => ({
        ...i,
        id: `${i.name}${i.photo}`,
        expanded: false,
      }))
    )

    const toggleExpandedState = (id) => {
      setFeedbacks(
        feedbacks.map((i) => ({
          ...i,
          expanded: i.id === id ? !i.expanded : i.expanded,
        }))
      )
    }

    return (
      <>
        <SharedH3
          as="h4"
          css={css`
            font-size: 2em;
          `}
        >
          Відгуки студентів
        </SharedH3>

        <SharedDivider />

        <ESection id="feedbackCarousel">
          <Slider
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            adaptiveHeight={true}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            autoplaySpeed={15000}
            dots={true}
            lazyLoad={false}
          >
            {feedbacks.map((i) => {
              return (
                <CarouselElementCourseStyle
                  key={i.id}
                  id={i.id}
                  expanded={i.expanded}
                  name={i.name}
                  purpose={i.purpose}
                  msg={i.msg}
                  photo={i.photo}
                  companyLogo={null}
                  componentNextToName={i.componentNextToName}
                  noExtend={i.noExtend}
                  toggleExpandedState={toggleExpandedState}
                />
              )
            })}
          </Slider>
        </ESection>
      </>
    )
  },
  (prev, next) => true
)

export const FeedbackCarouselCourseStyle = () => {
  return <Component />
}
