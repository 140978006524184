import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import img1 from "../../images/icons/bx-check-double.svg"
import img2 from "../../images/icons/refound.svg"
import img3 from "../../images/icons/bx-map.svg"
import img4 from "../../images/icons/Line14.svg"
import { CardButtonHref } from "../courses/VacancyCard"

const EH3 = styled.h4`
  font-size: 1.62em;
  font-weight: 600;
  color: #111;
  margin: 0;
  margin-bottom: 0.4em;
`

const IconsColumns = styled.div`
  display: grid;
  grid-template-columns: 1.54em 1fr;
  grid-column-gap: 1.47em;
  grid-row-gap: 1.17em;
  margin-top: 2em;
  align-items: center;

  img {
    max-height: 1.25em;
    align-self: center;
    justify-self: center;
  }

  p {
    font-size: 1.2em;
    font-weight: 500;
    @media (max-width: 640px) {
      font-size: 1.02em;
    }
    color: #111;
    margin: 0;
    line-height: 1.8;
  }
`

const EGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-column-gap: 0.73em;
  align-items: flex-start;
`

const ELine = styled.div`
  border-top: 1px solid #eceff4;
  margin: 2.4em 0;
  width: 100%;
`

const EH5 = styled.h5`
  margin: 0;
  font-size: 1.35em;
  font-weight: 700;
  color: #111;
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
  }
`

const EDescription = styled.p`
  font-size: 0.88em;
  color: #8f96a2;
`

export const Packages = ({
  name,
  pricePerCourse,
  pricePerMonth,
  discount,
  registerLink,
  goLearnYourselfLink,
  learnOnYOurselfPrice,
  buyOnUdemyCaption,
  buyOnUdemyCaption2,
  priceString,
  registrationClosed,
  registerLinkText,
}) => {
  return (
    <>
      <EH3>Оберіть свій пакет учасника</EH3>

      <ELine
        css={css`
          margin-bottom: 1.5em;
        `}
      />

      <EGrid>
        <div>
          <EH5
            css={css`
              font-size: 1.6em;
            `}
          >
            Навчання у групі із ментором
          </EH5>
          <IconsColumns>
            <>
              <div>
                <img src={img3} />
              </div>
              <p>Тернопіль, Івано-Франківськ</p>
            </>

            <div>
              <img src={img1} />
            </div>
            <p>Доступ до бази знань (відео + завдання)</p>

            <>
              <div>
                <img src={img1} />
              </div>
              <p>Доступ до slack спільноти курсів</p>
            </>

            <>
              <div>
                <img src={img1} />
              </div>
              <p>Код рев’ю протягом тривалості курсу</p>
            </>

            <>
              <div>
                <img src={img1} />
              </div>
              <p>Slack канал групи (25-30 учасників)</p>
            </>

            <>
              <div>
                <img src={img1} />
              </div>
              <p>Щотижневі групові заняття з ментором</p>
            </>
          </IconsColumns>
        </div>

        <div>
          {priceString ? (
            <EH5
              css={css`
                color: #fd7114;
              `}
            >
              {(priceString && name !== "Front-End") ? priceString : ""}
            </EH5>
          ) : (
            <>
              <EH5
                css={css`
                  color: #525151;
                `}
              >
                ₴{pricePerMonth}
                <span>/місяць</span>
              </EH5>
              <div>
                <img
                  src={img4}
                  css={css`
                    max-width: 10em;
                    height: 2px;
                    margin: 3px 0;
                  `}
                />
              </div>
              <EH5
                css={css`
                  color: #fd7114;
                `}
              >
                ₴{pricePerCourse}
                <span>/курс</span>
              </EH5>

              {discount && (
                <p
                  css={css`
                    color: #f5222d;
                    font-size: 12px;
                    margin: 3px 0 0 0;
                  `}
                >
                  знижка {discount}
                </p>
              )}
            </>
          )}
        </div>

        <div>
          <CardButtonHref
            href={registerLink}
            target="_blank"
            rel="noreferrer"
            orangeButton
            unactive={registrationClosed ? true : false}
          >
            {registerLinkText ? (
              <>{registerLinkText}</>
            ) : (
              <>Записатись на курс</>
            )}
          </CardButtonHref>

          <EDescription>
            {buyOnUdemyCaption
              ? buyOnUdemyCaption
              : "*Проходження буде відбуватись на платформі Udemy"}
          </EDescription>

          <div
            css={css`
              display: flex;
            `}
          >
            <img
              src={img2}
              css={css`
                height: 10em;
              `}
            />
          </div>
        </div>
      </EGrid>
      <ELine />

      <EGrid>
        <div>
          <EH5
            css={css`
              font-size: 1.6em;
            `}
          >
            Самостійне навчання
          </EH5>
          <IconsColumns>
            <>
              <div>
                <img src={img1} />
              </div>
              <p>Доступ до бази знань (відео + завдання)</p>
            </>

            <div>
              <img src={img1} />
            </div>
            <p>Доступ до slack спільноти курсів</p>
          </IconsColumns>
        </div>
        <EH5
          css={css`
            color: #fd7114;
          `}
        >
          {learnOnYOurselfPrice}
        </EH5>

        <div>
          <CardButtonHref
            href={goLearnYourselfLink === "" ? null : goLearnYourselfLink}
            absentLink={goLearnYourselfLink === ""}
          >
            Самостійно пройти
          </CardButtonHref>

          <EDescription>
            {buyOnUdemyCaption2
              ? buyOnUdemyCaption2
              : "*Проходження буде відбуватись на платформі Udemy"}
          </EDescription>
        </div>
      </EGrid>

      <ELine />

      <EGrid>
        <div>
          <EH5
            css={css`
              font-size: 1.6em;
            `}
          >
            Безкоштовний
          </EH5>

          <IconsColumns>
            <>
              <div>
                <img src={img1} />
              </div>
              <p>YouTube тека із 100+ навчальних відео</p>
            </>
          </IconsColumns>
        </div>
        <EH5
          css={css`
            color: #fd7114;
          `}
        >
          Free
        </EH5>

        <CardButtonHref
          href={"https://www.youtube.com/c/TernopilJS/videos"}
          target="_blank"
          rel="noreferrer"
        >
          Перейти на YouTube
        </CardButtonHref>
      </EGrid>

      <ELine />
    </>
  )
}
