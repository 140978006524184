import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { SingleElement } from "../faq/SingleElement"
import Faq from "../../templates/faq"

export const FaqCourse = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <Faq />
    </div>
  )
}
