import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import img1 from "../../images/icons/clock2.svg"
import img2 from "../../images/icons/play.svg"
import img3 from "../../images/icons/note2.svg"

const Wrapper = styled.div`
  background: #fafafa;
  border: 1px solid #e1e2e3;
  padding: 16px;
  border-radius: 6px;
  font-family: "Rubik", Arial, Helvetica, sans-serif;

  @media (max-width: 640px) {
    background: transparent;
    border: none;
    padding: 0px;
  }
`

const EH3 = styled.h3`
  font-size: 1.375em;
  font-weight: 600;
  color: #111;
  margin: 0;
  margin-bottom: 0.4em;
`

const IconsColumns = styled.div`
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 16px;
  margin-top: 1.4em;
  align-items: center;

  p {
    font-size: 1.2em;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    color: #111;
    margin: 0;
    line-height: 1.8;
  }
`

export const WhatYoullGet = ({ pracricalLessons, duration, videos }) => {
  if (!duration && !videos && !pracricalLessons) {
    return null
  }

  return (
    <>
      <Wrapper>
        <EH3>Протягом курсу ти отримаєш</EH3>

        <IconsColumns>
          {duration && (
            <>
              <div>
                <img src={img1} />
              </div>
              <p>{duration} навчання</p>
            </>
          )}

          {videos && (
            <>
              <div>
                <img src={img2} />
              </div>

              <p>{videos} навчальних відео</p>
            </>
          )}

          {pracricalLessons && (
            <>
              <div>
                <img src={img3} />
              </div>

              <p>{pracricalLessons}</p>
            </>
          )}
        </IconsColumns>
      </Wrapper>
    </>
  )
}
