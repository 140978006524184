import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import JsFontSize from "../JsFontSize"
import { SpinningApikoLoader } from "../SpinningApikoLoader"
import { YoutubeIframe } from "./YoutubeIframe"

const ContentWrapper = styled.div`
  max-width: 62em;
`

const EH3 = styled.h3`
  font-size: 1.5em;
  color: #111;
  margin: 1em 0;
`

const YoutubeDescription = styled.p`
  position: relative;
  margin: 0;
  font-size: 1.28em;
  color: #525151;
  line-height: 2.1;
  @media (max-width: 900px) {
    font-size: 14px;
  }

  a {
    color: #fd7114;
    text-decoration: none;
  }
`

export const VideoList = ({ lections, relevant }) => {
  return (
    <>
      <JsFontSize
        render={({ emsfontsize }) => {
          const videoWidth = emsfontsize * 16 * 27
          return (
            <ContentWrapper>
              {lections &&
                lections.map((i, index) => (
                  <React.Fragment key={`lections${index}${i.link}`}>
                    <div
                      css={css`
                        position: relative;
                      `}
                    >
                      <div
                        css={css`
                          font-size: 4em;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          z-index: 1;
                        `}
                      >
                        <SpinningApikoLoader />
                      </div>

                      <YoutubeIframe
                        index={index}
                        videoWidth={videoWidth}
                        src={i.link}
                      />
                    </div>

                    <EH3>{i.header}</EH3>

                    {i.presentationLink && (
                      <YoutubeDescription>
                        Презентація:{" "}
                        <a href={i.presentationLink} target="_blank" rel="noreferrer">
                          {i.presentationLink}
                        </a>
                      </YoutubeDescription>
                    )}

                    {i.description && (
                      <YoutubeDescription>{i.description}</YoutubeDescription>
                    )}
                  </React.Fragment>
                ))}
            </ContentWrapper>
          )
        }}
      />
    </>
  )
}
