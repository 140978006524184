import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ErrorSVG from "../../images/icons/bxs-error.svg"
import GradSVG from "../../images/icons/bxs-graduation.svg"
import vLineSVG from "../../images/icons/vLine.svg"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { CardButtonHref } from "../courses/VacancyCard"
import Sticky from "react-sticky-el"
import JsFontSize from "../JsFontSize"
import ScrollPosition from "../ScrollPosition"
import {
  SwitcherButton,
  EH2,
  SharedButtonsWrapper,
} from "./CourseLectionsSwitcher"
import { tabs } from "../../templates/Course"
import { FrontEnd2021InfoCard } from "./FrontEnd2021InfoCard"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const EH1 = styled.h1`
  margin: 0.5em 0 0.3em 0;
  font-size: 2.36em;
  font-weight: 600;
  color: ${(props) => (props.relevant ? "#111" : "#fff")};

  @media (max-width: 640px) {
    color: #111;
  }
`

const Tags = styled.p`
  margin: 0 0.6em 0 0;
  font-size: 1.2em;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  font-weight: 600;
  margin-right: 10px;
  color: #fd7114;
`

const SomeInfo = styled.div`
  display: grid;
  grid-template-columns: 14px max-content;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  img {
    max-height: 13px;
  }

  p {
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    color: ${(props) => (props.relevant ? "#111" : "#fff")};
  }

  @media (max-width: 640px) {
    p {
      color: #111;
    }
  }
`

const EDescription = styled.p`
  font-size: 1.2em;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  line-height: 1.8;
  max-width: 44em;
  color: ${(props) => (props.relevant ? "#525151" : "#E1E2E3")};
  margin: 1em 0 1.3em 0;

  @media (max-width: 640px) {
    font-size: 12px;
    color: #525151;
  }
`

const EBreadcrumbs = styled.div`
  font-size: 1.2em;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  line-height: 1.8;
  max-width: 48em;
  color: ${(props) => (props.relevant ? "#111" : "#E1E2E3")};

  margin: 0.8em 0 1.1em 0;

  a {
    color: ${(props) => (props.relevant ? "#8f96a1" : "#E1E2E3")};
    text-decoration: none;
  }

  @media (max-width: 640px) {
    color: #111;
  }
`

export const InfoCard = styled.div`
  background: #fff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 750;
  @media (min-width: 1100px) {
    transform: translateY(1em);
  }

  @media (max-width: 1100px) {
    position: relative;
  }
`

export const EInfoCardColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 12px;
  padding-top: 12px;
  border-top: 0.5px solid #e1e2e3;

  p {
    font-size: 1.2em;
    @media (max-width: 640px) {
      font-size: 14px;
    }
    margin: 0;
    line-height: 1.3;
  }

  p:nth-of-type(odd) {
    color: #8f96a1;
  }

  @media (min-width: 701px) {
    p:nth-of-type(even) {
      color: #111;
      font-weight: 600;
    }
  }
`

export const InfoCardWrapper = styled.div`
  position: relative;
`

const Wrapper2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 24em;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  max-width: 100em;
  margin: auto;
  padding: 24px 2em;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`

export const EPrice = styled.div`
  color: #525151;
  font-size: 1.22em;
  display: flex;
  align-items: center;

  p {
    font-size: 1.22em;
    margin: 0;
  }

  @media (max-width: 640px) {
    font-size: 14px;

    p {
      margin: 0;
      font-size: 18px;
      color: #111;
      font-weight: 600;
    }
  }
`

const FixedWrapper = styled.div`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  background-color: #111;
  box-shadow: "";
  margin-bottom: 1em;

  &[data-relevant="true"] {
    @media (min-width: 1100px) {
      background-color: #e3ebff;
    }
  }

  &[data-displayshadow="true"] {
    @media (min-width: 641px) {
      box-shadow: rgb(0 0 0 / 8%) 0px 0.36em 1.18em 0px;
    }
  }

  @media (max-width: 1100px) {
    background-color: #fff;
  }
`

const ShriveledDiv = styled.div`
  overflow: hidden;

  @media (min-width: 1101px) {
    height: ${(props) => (props.isOpen ? `${props.height}em` : "0em")};
  }
`

const EImg1 = styled(Img)`
  border-radius: 4px;

  img {
    object-fit: cover !important;
  }

  @media (min-width: 1080px) {
    height: ${(props) => (props.scrollPosition === 0 ? `auto` : "0em")};
  }
`

const Component = React.memo(
  ({
    startApplyDate,
    courseImageName,
    date,
    description,
    discount,
    name,
    shortName,
    pricePerCourse,
    pricePerMonth,
    relevant,
    tags,
    students,
    imgs,
    startCourseDate,
    startCourseDateReference,
    endCourseDate,
    duration,
    registerLink,
    registrationClosed,
    registerLinkText,
    priceString,
    courseStatus,
    setActiveTab,
    activeTab,
    scrollPositionLowerThan5,
    scrollPositionNotEquals0,
    link,
    secondInfoCardCourse,
    width,
    scrollPosition,
  }) => {
    const img = imgs.allFile.edges.find((i) => i.node.name === courseImageName)
    const secondInfoCardCourseImg = imgs.allFile.edges.find(
      (i) => i.node.name === secondInfoCardCourse
    )

    return (
      <>
        {relevant ? (
          <FixedWrapper
            data-relevant={!!relevant}
            data-displayshadow={scrollPositionNotEquals0}
          >
            <Wrapper2>
              <Wrapper>
                <ShriveledDiv isOpen={scrollPositionLowerThan5} height={3}>
                  <EBreadcrumbs
                    css={css`
                      color: #111;
                    `}
                  >
                    <Link
                      to="/courses/"
                      css={css`
                        color: #8f96a1 !important;

                        @media (max-width: 640px) {
                          color: #8f96a1 !important;
                        }
                      `}
                    >
                      Наші курси{" "}
                    </Link>
                    / {shortName}
                  </EBreadcrumbs>
                </ShriveledDiv>

                <EH1 relevant>{name}</EH1>

                <ShriveledDiv
                  isOpen={scrollPositionLowerThan5}
                  height={description.length * 0.052}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                    `}
                  >
                    {tags.map((i) => (
                      <Tags relevant={relevant} key={`${name}${i}`}>
                        #{i}
                      </Tags>
                    ))}
                  </div>

                  <EDescription relevant>{description}</EDescription>
                </ShriveledDiv>

                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <SomeInfo relevant>
                    <div>
                      <img src={ErrorSVG} />
                    </div>
                    <p>{courseStatus}</p>
                  </SomeInfo>

                  {students && (
                    <SomeInfo relevant>
                      <div>
                        <img src={GradSVG} />
                      </div>
                      <p>({students} students)</p>
                    </SomeInfo>
                  )}
                </div>
              </Wrapper>

              {link === "front-end_2021" && (
                <FrontEnd2021InfoCard
                  img={img}
                  registerLink={registerLink}
                  pricePerCourse={pricePerCourse}
                  secondInfoCardCourseImg={secondInfoCardCourseImg}
                  scrollPosition={scrollPosition}
                />
              )}

              {link !== "front-end_2021" && (
                <InfoCardWrapper>
                  <InfoCard>
                    {img && (
                      <EImg1
                        durationFadeIn={200}
                        fluid={img.node.childImageSharp.fluid}
                        scrollPosition={scrollPosition}
                      />
                    )}

                    {priceString ? (
                      <EPrice
                        css={css`
                          margin-top: 0.5em;
                        `}
                      >
                        <p>{priceString}</p>
                      </EPrice>
                    ) : (
                      <>
                        <div
                          css={css`
                            display: grid;
                            grid-template-columns: 1fr min-content 1fr;
                            grid-column-gap: 0.6em;
                            margin-top: 1.6em;
                          `}
                        >
                          <EPrice>
                            <p>₴{pricePerCourse}</p>/курс
                          </EPrice>

                          <div
                            css={css`
                              width: 1px;
                            `}
                          >
                            <img
                              src={vLineSVG}
                              css={css`
                                @media (max-width: 640px) {
                                  display: none;
                                }
                              `}
                            />
                          </div>

                          <EPrice>
                            <p>₴{pricePerMonth}</p>/місяць
                          </EPrice>
                        </div>

                        {discount && (
                          <p
                            css={css`
                              color: #f5222d;
                              font-size: 12px;
                              margin: 3px 0 0 0;
                            `}
                          >
                            знижка {discount} *
                          </p>
                        )}
                      </>
                    )}

                    <CardButtonHref
                      href={registerLink}
                      target="_blank"
                      rel="noreferrer"
                      orangeButton
                      unactive={registrationClosed ? true : false}
                      css={css`
                        margin-top: 1.22em;
                      `}
                    >
                      {registerLinkText ? (
                        <>{registerLinkText}</>
                      ) : (
                        <>Записатись на курс</>
                      )}
                    </CardButtonHref>
                    {pricePerCourse && (
                      <p
                        css={css`
                          font-size: 0.88em;
                          color: #8f96a2;
                        `}
                      >
                        *При оплаті курсу одним платежем
                      </p>
                    )}

                    {startCourseDateReference && (
                      <p
                        css={css`
                          margin: 0;
                          color: #8f96a2;
                          font-size: 0.88em;
                        `}
                      >
                        **дату початку буде оголошено після формування
                        навчальної групи
                      </p>
                    )}

                    <EInfoCardColumn>
                      {startApplyDate && (
                        <>
                          <p>Початок набору: </p>
                          <p>{startApplyDate}</p>
                        </>
                      )}

                      {startCourseDate &&
                      startCourseDate.length &&
                      startCourseDate.length > 0 ? (
                        <>
                          <p>
                            Старт курсу:
                            {startCourseDateReference && (
                              <span
                                css={css`
                                  color: #f5222d;
                                  font-size: 1em;
                                `}
                              >
                                {" "}
                                **
                              </span>
                            )}
                          </p>
                          <p>{startCourseDate}</p>
                        </>
                      ) : (
                        <>
                          <p>Старт курсу: </p>
                          <p>{date}</p>
                        </>
                      )}

                      {duration && (
                        <>
                          <p>Тривалість: </p>
                          <p>{duration}</p>
                        </>
                      )}

                      {/* {endCourseDate && (
                      <>
                        <p>Завершення курсу: </p>
                        <p>{endCourseDate}</p>
                      </>
                    )} */}

                      <p>Проведення: </p>
                      <p>Online</p>
                      <p>Мова: </p>
                      <p>Українська</p>
                    </EInfoCardColumn>
                  </InfoCard>
                </InfoCardWrapper>
              )}
            </Wrapper2>

            <div
              css={css`
                background: #fff;
                overflow-x: auto;
              `}
            >
              <SharedButtonsWrapper>
                <SwitcherButton
                  active={activeTab === tabs[0]}
                  onClick={() => {
                    setActiveTab(tabs[0])
                  }}
                >
                  <EH2>Про Курс</EH2>
                </SwitcherButton>

                <SwitcherButton
                  active={activeTab === tabs[3]}
                  onClick={() => {
                    setActiveTab(tabs[3])
                  }}
                >
                  <EH2>Пакети учасника</EH2>
                </SwitcherButton>

                <SwitcherButton
                  active={activeTab === tabs[2]}
                  onClick={() => {
                    setActiveTab(tabs[2])
                  }}
                >
                  <EH2>Вимоги до кандидата</EH2>
                </SwitcherButton>

                <SwitcherButton
                  active={activeTab === tabs[1]}
                  onClick={() => {
                    setActiveTab(tabs[1])
                  }}
                >
                  <EH2>Розклад</EH2>
                </SwitcherButton>

                <SwitcherButton
                  active={activeTab === tabs[4]}
                  onClick={() => {
                    setActiveTab(tabs[4])
                  }}
                >
                  <EH2>FAQs</EH2>
                </SwitcherButton>
              </SharedButtonsWrapper>
            </div>
          </FixedWrapper>
        ) : (
          <FixedWrapper displayShadow={scrollPositionNotEquals0}>
            <Wrapper2>
              <Wrapper>
                <ShriveledDiv isOpen={scrollPositionLowerThan5} height={3}>
                  <EBreadcrumbs>
                    <Link
                      to="/courses/"
                      css={css`
                        @media (max-width: 640px) {
                          color: #8f96a1 !important;
                        }
                      `}
                    >
                      Наші курси{" "}
                    </Link>
                    / {shortName}
                  </EBreadcrumbs>
                </ShriveledDiv>

                <EH1>{name}</EH1>

                <ShriveledDiv isOpen={scrollPositionLowerThan5} height={3}>
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                    `}
                  >
                    {tags.map((i) => (
                      <Tags relevant={relevant} key={`${name}${i}`}>
                        #{i}
                      </Tags>
                    ))}
                  </div>

                  <EDescription>{description}</EDescription>
                </ShriveledDiv>

                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <SomeInfo>
                    <div>
                      <img src={ErrorSVG} />
                    </div>
                    <p>Курс завершився</p>
                  </SomeInfo>

                  {students && (
                    <SomeInfo>
                      <div>
                        <img src={GradSVG} />
                      </div>
                      <p>({students} students)</p>
                    </SomeInfo>
                  )}
                </div>
              </Wrapper>

              <InfoCardWrapper>
                <InfoCard>
                  {img && (
                    <Img
                      durationFadeIn={200}
                      fluid={img.node.childImageSharp.fluid}
                      css={css`
                        border-radius: 4px;

                        img {
                          object-fit: cover !important;
                        }
                      `}
                    />
                  )}

                  <EInfoCardColumn>
                    {startCourseDate ? (
                      <>
                        <p>Завершився: </p>
                        <p>{startCourseDate}</p>
                      </>
                    ) : (
                      <>
                        <p>Завершився: </p>
                        <p>{date}</p>
                      </>
                    )}
                    <p>Проведення: </p>
                    <p>offline</p>
                    {duration && (
                      <>
                        <p>Тривалість: </p>
                        <p>{duration}</p>
                      </>
                    )}
                    <p>Мова: </p>
                    <p>Українська</p>
                  </EInfoCardColumn>
                </InfoCard>
              </InfoCardWrapper>
            </Wrapper2>
          </FixedWrapper>
        )}
      </>
    )
  },
  (prev, next) =>
    prev.activeTab === next.activeTab &&
    prev.scrollPositionLowerThan5 === next.scrollPositionLowerThan5 &&
    prev.scrollPositionNotEquals0 === next.scrollPositionNotEquals0
)

const StickyTopNav = styled.div`
  margin-top: ${(props) => props.marginTop};

  .StickyTopNavigation {
    width: 100%;
    left: 0;
    right: 0;
    position: relative;
    z-index: 666;
  }

  &[data-nodisplay="true"] {
    display: none;
  }
`

const StickyWrap2 = ({
  courseImageName,
  date,
  description,
  discount,
  name,
  shortName,
  pricePerMonth,
  relevant,
  tags,
  students,
  imgs,
  startApplyDate = null,
  startCourseDate,
  startCourseDateReference,
  endCourseDate = null,
  duration,
  registerLink,
  priceString,
  courseStatus,
  setActiveTab,
  activeTab,
  pricePerCourse,
  registerLinkText,
  registrationClosed,
  link,
  secondInfoCardCourse,
  scrollPosition,
}) => {
  return (
    <JsFontSize
      render={({ emsfontsize, width }) => {
        const headerSize = scrollPosition > 1 ? 4 : 6
        const topOffset = headerSize * 16 * emsfontsize

        const martingTop = scrollPosition !== 0 ? "4em" : "6em"

        const scrollPositionNotEquals0 = scrollPosition !== 0
        const scrollPositionLowerThan5 = scrollPosition < 5

        return (
          <>
            {width > 1200 && (
              <StickyTopNav data-nodisplay={width < 1201}>
                <Sticky
                  className="StickyTopNavigation"
                  topOffset={-topOffset}
                  positionRecheckInterval={1}
                  disabled={false}
                  stickyStyle={{
                    boxShadow: "0",
                    zIndex: 1333,
                    marginTop: martingTop,
                    // transform: "unset",
                  }}
                >
                  <Component
                    scrollPosition={scrollPosition}
                    courseImageName={courseImageName}
                    date={date}
                    description={description}
                    discount={discount}
                    name={name}
                    shortName={shortName}
                    pricePerCourse={pricePerCourse}
                    pricePerMonth={pricePerMonth}
                    relevant={relevant}
                    tags={tags}
                    students={students}
                    imgs={imgs}
                    startApplyDate={startApplyDate}
                    startCourseDate={startCourseDate}
                    startCourseDateReference={startCourseDateReference}
                    endCourseDate={endCourseDate}
                    duration={duration}
                    registerLink={registerLink}
                    priceString={priceString}
                    courseStatus={courseStatus}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    scrollPositionLowerThan5={scrollPositionLowerThan5}
                    scrollPositionNotEquals0={scrollPositionNotEquals0}
                    registerLinkText={registerLinkText}
                    registrationClosed={registrationClosed}
                    link={link}
                    secondInfoCardCourse={secondInfoCardCourse}
                    width={width}
                  />
                </Sticky>
              </StickyTopNav>
            )}

            {width < 1201 && (
              <StickyTopNav
                marginTop={martingTop}
                data-nodisplay={width > 1200}
              >
                <Component
                  courseImageName={courseImageName}
                  date={date}
                  description={description}
                  discount={discount}
                  name={name}
                  shortName={shortName}
                  pricePerCourse={pricePerCourse}
                  pricePerMonth={pricePerMonth}
                  relevant={relevant}
                  tags={tags}
                  students={students}
                  imgs={imgs}
                  startApplyDate={startApplyDate}
                  startCourseDate={startCourseDate}
                  startCourseDateReference={startCourseDateReference}
                  endCourseDate={endCourseDate}
                  duration={duration}
                  registerLink={registerLink}
                  priceString={priceString}
                  courseStatus={courseStatus}
                  scrollPosition={scrollPosition}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  scrollPositionLowerThan5={scrollPositionLowerThan5}
                  scrollPositionNotEquals0={scrollPositionNotEquals0}
                  registerLinkText={registerLinkText}
                  registrationClosed={registrationClosed}
                  link={link}
                  secondInfoCardCourse={secondInfoCardCourse}
                  width={width}
                />
              </StickyTopNav>
            )}
          </>
        )
      }}
    />
  )
}

const StickyWrap = (props) => {
  return (
    <ScrollPosition
      render={({ scrollPosition }) => (
        <StickyWrap2
          {...props}
          scrollPosition={scrollPosition < 77 ? scrollPosition : 77}
        />
      )}
    />
  )
}

export const CourseHeader = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "course-image" } }) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(
                  pngCompressionSpeed: 1
                  quality: 70
                  srcSetBreakpoints: [300]
                  maxWidth: 300
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(imgs) => (
      <>
        <StickyWrap imgs={imgs} {...props} />
      </>
    )}
  />
)
