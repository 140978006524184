import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import img2 from "../../images/icons/linkedin.svg"
import img22 from "../../images/icons/linkedin2.svg"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

const Wrapper = styled.section`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

const EH3 = styled.h3`
  font-size: 1.7em;
  font-weight: 600;
  color: #111;
  margin: 0;

  @media (max-width: 640px) {
    margin: 0.5em 0;
  }
`

const EImg = styled(Img)`
  width: 14em !important;
  height: 14em !important;

  img {
    border-radius: 8px;
    object-fit: cover !important;
  }

  @media (max-width: 640px) {
    width: 18em !important;
    height: 18em !important;
  }
`

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3em;

  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Position = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
  color: #8f96a2;
  font-weight: 600;
  font-size: 12px;
  margin: 0.5em 0 0 0;

  @media (max-width: 640px) {
    display: none;
    font-weight: 400;
  }
`

const Position2 = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
  color: #8f96a2;
  font-weight: 600;
  font-size: 14px;
  margin: 0.5em 0 5px 0;
  border-bottom: 1px solid #fd7114;
  padding-bottom: 7px;

  @media (min-width: 641px) {
    display: none;
    margin: 1em 0 1em 0;
  }
`

const SocialLinksBlock = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding-top: 0.3em;

  img {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 641px) {
    a {
      margin-left: 20px;
    }
  }

  @media (max-width: 640px) {
    margin: auto;
  }
`

const EP = styled.p`
  font-size: 1.2em;

  color: #111;
  margin: 0;
  line-height: 1.8;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 1.4;
  }
`

const Description1Wrapper = styled.div`
  max-height: 8em;
  overflow: hidden;

  &[data-showall="true"] {
    max-height: unset;
  }
`

const ReadMoreTard = styled.button`
  color: #6c8de0;
  border: 0;
  background: transparent;
  padding: 0;
  font-size: 14px;
`

const Description1 = ({ description, name }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Description1Wrapper data-showall={isOpen}>
        {description.map((i) => (
          <EP key={`${name}${i.substring(0, 6)}`}>{i}</EP>
        ))}
      </Description1Wrapper>
      <ReadMoreTard
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        {isOpen ? <>Менше</> : <>Читати більше</>}
      </ReadMoreTard>
    </>
  )
}
const Component = ({
  pic,
  name,
  position,
  description,
  linkedin,

  imgs: {
    allFile: { edges },
  },
}) => {
  if (!pic) return null

  const imgSharp = edges.find((i) => i.node.name === pic).node.childImageSharp

  return (
    <Wrapper>
      <div
        css={css`
          display: grid;
          grid-template-columns: min-content 1fr;
          grid-column-gap: 24px;

          @media (max-width: 640px) {
            grid-template-columns: 1fr;
          }
        `}
      >
        <EImg
          fixed={imgSharp.fixed}
          durationFadeIn={200}
          css={css`
            @media (max-width: 640px) {
              margin: auto;
            }
            margin: auto;
          `}
        />

        <div>
          <HeaderBlock>
            <div
              css={css`
                @media (max-width: 640px) {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                }
              `}
            >
              <EH3>{name}</EH3>
              <Position>{position}</Position>
            </div>

            <SocialLinksBlock>
              {linkedin && (
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <img
                    src={img2}
                    css={css`
                      @media (max-width: 640px) {
                        display: none;
                      }
                    `}
                  />
                  <img
                    src={img22}
                    css={css`
                      @media (min-width: 641px) {
                        display: none;
                      }
                    `}
                  />
                </a>
              )}
            </SocialLinksBlock>
          </HeaderBlock>

          <Position2>{position}</Position2>

          <div
            css={css`
              @media (max-width: 640px) {
                display: none;
              }
            `}
          >
            {description.map((i) => (
              <EP key={`${name}${i.substring(0, 6)}`}>{i}</EP>
            ))}
          </div>

          <div
            css={css`
              @media (min-width: 641px) {
                display: none;
              }
            `}
          >
            <Description1 description={description} name={name} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export const Mentors = React.memo(
  (props) => (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "mentor" } }) {
            edges {
              node {
                id
                name
                absolutePath
                relativePath
                publicURL
                childImageSharp {
                  fixed(width: 200, pngCompressionSpeed: 1, quality: 60) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      `}
      render={(imgs) => <Component {...props} imgs={imgs} />}
    />
  ),
  () => true
)
