import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import JsFontSize from "../JsFontSize"
import { SpinningApikoLoader } from "../SpinningApikoLoader"
import VisibilitySensor from "react-visibility-sensor"

const EWrapper = styled.div`
  height: ${(props) => `${props.height}px`};
  margin-top: ${({ marginTop }) => `${marginTop}em`};
`

export const YoutubeIframe = ({ index, videoWidth, src }) => {
  const [inView, setInView] = useState(false)
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    setRendered(true)
  }, [])

  return (
    <EWrapper height={videoWidth}>
      {rendered && (
        <VisibilitySensor
          partialVisibility={true}
          onChange={(e) => {
            if (e) {
              setInView(true)
            } else {
              setInView(false)
            }
          }}
        >
          <EWrapper height={videoWidth} marginTop={index === 0 ? 1.3 : 3.3}>
            {inView && (
              <iframe
                css={css(
                  `border-radius: 10px; position: relative; z-index: 3;`
                )}
                width="100%"
                height={videoWidth}
                src={src}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            )}
          </EWrapper>
        </VisibilitySensor>
      )}
    </EWrapper>
  )
}
