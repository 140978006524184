import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

const spinQuater = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const EWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.2, 0.3, 0.25, 0.9);
  position: absolute;
  width: 3.5em;
  height: 3.5em;
  overflow: hidden;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  margin: auto;
`
const EWrapper2 = styled.div`
  width: 3.5em;
  height: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`
const ELogowrapper = styled.div`
  width: 3.5em;
  height: 3.5em;
  border: 0.5em solid #333333;
  border-radius: 100%;
  position: absolute;
`
const EQuarter = styled.div`
  background: transparent;
  width: 3em;
  height: 3em;
  border-bottom: 1.5em solid #ff9900;
  border-left: 1.5em solid #fff;
  border-top: 1.5em solid #fff;
  border-right: 1.5em solid #fff;
  animation: ${spinQuater} 1400ms linear infinite;
  z-index: -1;
  position: absolute;
  border-radius: 100%;
`
const ELeftline = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1em;
  width: 0.56em;
  background: #333333;
  border-radius: 1em;
  border-top-left-radius: 100%;
`
const ELeftlinesmall = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.5em;
  height: 1.3em;
  width: 0.2em;
  background: #333333;
  border-radius: 1em;
  border-top-left-radius: 100%;
`

export const SpinningApikoLoader = () => {
  return (
    <EWrapper>
      <EWrapper2>
        <ELeftline />
        <ELeftlinesmall />
        <ELogowrapper />
        <EQuarter />
      </EWrapper2>
    </EWrapper>
  )
}
