import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import { SharedDivider } from "../shared"

const EH3 = styled.h3`
  font-size: 22px;
  font-weight: 600;
  color: #111;
  margin: 0;
  margin-bottom: 0.4em;
`

const EWrapper = styled.div`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  border-radius: 6px;
  position: relative;

  @media (min-width: 641px) {
    padding: 16px;
    border: 1px solid #e1e2e3;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: center;
`

const EFeatures = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 26px;
  margin-top: 1.8em;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain !important;
    pointer-events: none;
  }
`

const ThisH3Description = styled.p`
  font-size: 1.4em;
  color: #525151;
  line-height: 1.8;
  margin: 0;
  padding: 0;
  text-align: initial;

  a {
    color: #3489e4;
    text-decoration: none;
  }

  @media (max-width: 640px) {
    font-size: 1.6em;
    a {
      display: none;
    }
  }
`

const FeaturesImg = styled(Img)`
  height: 24px !important;
  width: 30px !important;
  object-fit: contain !important;
`

const Component = ({ imgs }) => {
  return (
    <>
      <EWrapper>
        <LeftBlock>
          <EH3
            css={css`
              @media (max-width: 640px) {
                text-align: center;
              }
            `}
          >
            Наближеність до реальної роботи
          </EH3>

          <ThisH3Description
            css={css`
              max-width: 37em;
              font-size: 1.4em !important;
            `}
          >
            Ми вирішили наші курси максимально наблизити до реальної роботи,
            тому весь інтенсив буде проходити:
          </ThisH3Description>

          <EFeatures>
            <a href="https://gitlab.com" target="_blank" rel="noreferrer">
              <FeaturesImg
                fixed={imgs.i1.childImageSharp.fixed}
                durationFadeIn={200}
              />
            </a>
            <ThisH3Description>
              <a href="https://gitlab.com" target="_blank" rel="noreferrer">
                Gitlab.com
              </a>{" "}
              для репозиторію та задач
            </ThisH3Description>

            <a href="https://figma.com" target="_blank" rel="noreferrer">
              <FeaturesImg
                fixed={imgs.i2.childImageSharp.fixed}
                durationFadeIn={200}
              />
            </a>
            <ThisH3Description>
              <a href="https://figma.com" target="_blank" rel="noreferrer">
                Figma.com
              </a>{" "}
              робота з дизайнами
            </ThisH3Description>

            <a href="https://slack.com" target="_blank" rel="noreferrer">
              <FeaturesImg
                fixed={imgs.i3.childImageSharp.fixed}
                durationFadeIn={200}
              />
            </a>
            <ThisH3Description>
              <a href="https://slack.com" target="_blank" rel="noreferrer">
                Slack.com
              </a>{" "}
              для спілкування з командою
            </ThisH3Description>

            <a href="https://zoom.us" target="_blank" rel="noreferrer">
              <FeaturesImg
                fixed={imgs.i4.childImageSharp.fixed}
                durationFadeIn={200}
              />
            </a>
            <ThisH3Description>
              <a href="https://zoom.us" target="_blank" rel="noreferrer">
                Zoom.us
              </a>{" "}
              щотижневе групове заняття із ментором
            </ThisH3Description>

            <a href="https://www.udemy.com/" target="_blank" rel="noreferrer">
              <FeaturesImg
                fixed={imgs.i5.childImageSharp.fixed}
                durationFadeIn={200}
              />
            </a>
            <ThisH3Description>
              <a href="https://www.udemy.com/" target="_blank" rel="noreferrer">
                udemy
              </a>{" "}
              доступні онлайн матеріали
            </ThisH3Description>
          </EFeatures>
        </LeftBlock>

        <Img
          fluid={imgs.github.childImageSharp.fluid}
          durationFadeIn={200}
          css={css`
            z-index: -1;
            width: 50%;
            right: 0;
            position: absolute !important;
            bottom: 0;

            img {
              object-fit: contain !important;
              pointer-events: none;
            }

            @media (max-width: 640px) {
              display: none;
            }
          `}
        />
      </EWrapper>
    </>
  )
}

export const AlmostRealJob = React.memo(
  () => (
    <StaticQuery
      query={graphql`
        query {
          github: file(relativePath: { eq: "home/githab2.png" }) {
            childImageSharp {
              fluid(pngCompressionSpeed: 1, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          i1: file(relativePath: { eq: "home/i1.png" }) {
            childImageSharp {
              fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          i2: file(relativePath: { eq: "home/i2.png" }) {
            childImageSharp {
              fixed(pngCompressionSpeed: 1, quality: 50, width: 36) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          i3: file(relativePath: { eq: "home/i3.png" }) {
            childImageSharp {
              fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          i4: file(relativePath: { eq: "home/i4.png" }) {
            childImageSharp {
              fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          i5: file(relativePath: { eq: "home/i5.png" }) {
            childImageSharp {
              fixed(pngCompressionSpeed: 1, quality: 50, width: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(imgs) => <Component imgs={imgs} />}
    />
  ),
  () => true
)
