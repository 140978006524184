import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { VideoList } from "./VideoList"
import { SharedDivider } from "../shared"

export const Wrapper = styled.div`
  max-width: 100em;
  margin: auto;
  overflow: hidden;
  padding: 0em 2em;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
`

export const SwitcherButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  margin: 0 4.4em 0 0;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  min-height: 60px;
  border-bottom: ${(props) =>
    props.active ? "5px solid #111" : "5px solid transparent"};
  transition: all 0.1s;

  @media (max-width: 1100px) {
    margin: 0 1em 0 0;
  }

  @media (max-width: 641px) {
    border-bottom: ${(props) =>
      props.active ? "2px solid #111" : "1px solid #e0e2e3"};
    margin: 0;
    padding-right: 1em;
  }
`

export const EH2 = styled.h2`
  padding: 0;
  margin: 0;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  white-space: nowrap;
  color: #000;
`

export const SharedButtonsWrapper = styled.div`
  display: flex;
  max-width: 100em;
  margin: auto;
  padding: 0 2em;
  min-height: 8em;
  align-items: center;
`

export const ContentWrapper = styled.div`
  max-width: 62em;
`

export const CourseLectionsSwitcher = ({ lections, relevant, team }) => {
  const [activeTab, setActiveTab] = useState("Lections")

  return (
    <>
      <SharedButtonsWrapper
        css={css`
          pointer-events: none;
        `}
      >
        <SwitcherButton active={activeTab === "Lections"}>
          <EH2>Лекції</EH2>
        </SwitcherButton>
      </SharedButtonsWrapper>

      {activeTab === "Lections" && !!lections && (
        <Wrapper active={true}>
          <VideoList lections={lections} />
        </Wrapper>
      )}

      <SharedDivider data-count={2} />
    </>
  )
}
