import React, { useState } from "react"
import { css } from "@emotion/react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import Header from "../components/Header"
import { CourseHeader } from "../components/course/CourseHeader"
import { CourseLectionsSwitcher } from "../components/course/CourseLectionsSwitcher"
import { CourseLectionsSwitcherRelevant } from "../components/course/CourseLectionsSwitcherRelevant"

export const tabs = ["t1", "t2", "t3", "t4", "t5"]

const Course = (props) => {
  const { pageContext } = props
  const [activeTab, setActiveTab] = useState(tabs[0])

  return (
    <>
      <SEO />

      <Helmet
        title={`${pageContext.name}`}
        meta={[
          {
            name: "description",
            content: `${pageContext.description}`,
          },
        ]}
        link={[
          {
            rel: "canonical",
            href: `https://apiko.academy/course/${pageContext.link}/`,
          },
        ]}
      />
      <Helmet>
        <html lang="uk" />
      </Helmet>

      {/* <CourseLeftAside {...pageContext} /> */}

        <CourseHeader
          {...pageContext}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      <div
        css={css`
          min-height: 1000px;
        `}
      >
        <Header noBottomShadow />
        <div
          css={css`
            min-height: 70vh;
          `}
        >
          {pageContext.relevant && (
            <div>
              <CourseLectionsSwitcherRelevant
                {...pageContext}
                activeTab={activeTab}
              />
            </div>
          )}

          {!pageContext.relevant && <CourseLectionsSwitcher {...pageContext} />}
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Course
